import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import logo_topo from "../../assets/logo_topo.png";
import whats from "../../assets/whats.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container vertical">
          <figure className="top">
            <img src={logo_topo} alt="Logo" />
          </figure>

          <h1 className="title">
            Aprovação de projetos, licenças e regularização de imóveis na cidade
            de São Paulo, junto à PMSP e demais órgãos públicos.
          </h1>

          <CtaWpp />
        </article>
      </section>

      <section className="mobile">
        <article className="container">
          <CtaWpp />
        </article>
      </section>

      <figure id="wpp">
        <a
          href="http://wa.me/+5511994858068?text=Solicitar%20Orçamento"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whats} alt="Ícone do Whatsapp" />
        </a>
      </figure>

      <section className="header2">
        <article className="container vertical">
          <h2 className="title">
            Excelência em consultoria de legislação urbana e aprovação de
            projetos em São Paulo.
          </h2>

          <p className="text">
            Contamos com uma vasta rede de clientes e parceiros construtores que
            reconhecem a qualidade e confiabilidade dos nossos serviços de
            aprovações e consultoria imobiliária, especialmente junto à
            Prefeitura de São Paulo.
          </p>

          <CtaWpp />
        </article>
      </section>
    </header>
  );
};

export default Header;
