import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import about3_1 from "../../assets/about3_1.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">Nossos serviços:</h2>

          <div className="faq">
            <details className="first-row">
              <summary>
                ALVARÁS | Prefeitura do Município de São Paulo (PMSP)
              </summary>

              <ul>
                <li>
                  Alvará de Aprovação e Execução de Edificação Nova/Reforma;
                </li>
                <li>
                  Projeto Modificativo de Alvará de Aprovação e Execução de
                  Edificação Nova;
                </li>
                <li>Apostilamento de Alvará de Aprovação e Execução;</li>
                <li>Troca de Responsabilidade Técnica em Alvarás;</li>
                <li>Alvará de Autorização de Estande;</li>
                <li>Alvará de Autorização de Tapume;</li>
                <li>Alvará de Execução de Demolição;</li>
                <li>Certificado de Conclusão de Demolição;</li>
                <li>Certificado de Conclusão de Obra (Habite-se);</li>
                <li>Cópias de Processos Administrativos arquivados na PMSP;</li>
              </ul>
            </details>

            <details className="first-row">
              <summary>
                LICENÇAS | Prefeitura do Município de São Paulo (PMSP)
              </summary>

              <ul>
                <li>
                  Licença de Funcionamento (Incluindo Licença de Bombeiros,
                  Licença de Vigilância Sanitária);
                </li>
                <li>Cadastro de Anúncio;</li>
              </ul>
            </details>

            <details className="second-row">
              <summary>ESTUDO DE IMÓVEIS/TERRENOS</summary>

              <ul>
                <li>Estudo de Viabilidade Técnica e Legal;</li>
              </ul>
            </details>

            <details className="second-row">
              <summary>CERTIDÕES</summary>

              <ul>
                <li>
                  <b>Para imóveis:</b> Certidão de Desapropriação, Certidão de
                  Melhoramento Viário, Certidão de Diretrizes Hidráulicas,
                  Certidão Negativa de Multas, Certidão de Tombamento, Certidão
                  do Departamento Ambiental, Certidão de Zoneamento, Certidão de
                  tributos imobiliários do Município de São Paulo para todos os
                  contribuintes, entre outras;{" "}
                </li>

                <li className="mt">
                  <b>Para Empresas:</b> Certidão Estadual de Distribuições
                  Cíveis, Certidões de Ações Trabalhistas, Certidões de Débitos
                  Trabalhistas, Certidão de débitos relativos aos tributos
                  federais e à dívida ativa da união, entre outras;
                </li>
              </ul>
            </details>

            <details className="third-row">
              <summary>LEGALIZAÇÃO EM DEMAIS ÓRGÃOS PÚBLICOS - SP</summary>

              <ul>
                <li>
                  SVMA/DECONT, CETESB, VIGILÂNCIA SANITÁRIA, CONPRESP,
                  CONDEPHAAT, IPHAAN, SIURB, COMAER, etc.
                </li>
              </ul>
            </details>

            <details className="third-row">
              <summary>ENGENHARIA CIVIL</summary>

              <ul>
                <li>
                  Emissão de Laudos, Estudos, com Anotação de Responsabilidade
                  Técnica (ART).
                </li>
              </ul>
            </details>
          </div>

          <CtaWpp />
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">Nosso diferencial</h2>

          <div className="gallery">
            <div className="item first">
              <p className="item_title">EFICIÊNCIA</p>

              <p className="item_text">
                Nossa eficiência resulta na aprovação dos projetos de forma mais
                rápida e profissional, garantindo o sucesso dos empreendimentos
                imobiliários dos nossos clientes.
              </p>
            </div>

            <div className="item second">
              <p className="item_title">EXPERIÊNCIA</p>

              <p className="item_text">
                Nossa experiência nos permite lidar de forma ágil e profissional
                com desafios complexos e garantir conformidade legal, oferecendo
                orientação confiável e valiosos insights para clientes e equipes
                de desenvolvimento.
              </p>
            </div>

            <div className="item third">
              <p className="item_title">conhecimento</p>

              <p className="item_text">
                Contamos com profissionais de vasto conhecimento das leis e
                regulamentos locais, além de habilidades em resolver desafios
                complexos e manter relacionamentos eficazes com autoridades
                municipais.
              </p>
            </div>
          </div>

          <CtaWpp />
        </div>
      </article>

      <article className="about3">
        <div className="container horizontal">
          <div className="left">
            <h2 className="title">Quem sou eu:</h2>

            <p className="text">
              Engenheira Civil, formada da Universidade Presbiteriana Mackenzie
              – atuo há mais de 10 anos na área de aprovação/legalização de
              projetos, ajudando os clientes, parceiros,
              incorporadores/construtores a obter Alvarás, Certidões e Licenças
              necessárias com agilidade. Pleno conhecimento na legislação
              vigente: LUOS, COE, Zoneamento, PDE, e demais Leis e
              Decretos correlatados.
            </p>

            <CtaWpp text={"Fale comigo"} />
          </div>

          <figure className="right">
            <img src={about3_1} alt=" Letícia Fabretto Fernandes" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
